<template>
  <div>
    <toasts />
    <router-view />
  </div>
</template>

<script>
import Toasts from '@/components/Toasts.vue'
const fullHeight = function () {
  document.getElementsByClassName('js-fullheight')[0].style.height = window.innerHeight + 'px'
}
export default {
  components: { Toasts },
  created () {
    fullHeight()
    window.addEventListener('resize', fullHeight)
  }
}
</script>

<style>
</style>
