<template>
  <div class="bootstrap-alert">
    <div v-for="item in list"
         :key="item.id"
         :class="getClass(item.type)"
         role="alert"
         v-text="item.msg">
    </div>
  </div>
</template>

<script>
let id = 0
let taht
const toasts = function toasts (type, msg) {
  taht.putMsg(type, msg)
}
export default {
  toasts,
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Toasts',
  created () {
    taht = this
  },
  beforeUnmount () {
    window.clearInterval(this.timer)
  },
  data () {
    return {
      timer: undefined,
      list: []
    }
  },
  methods: {
    /**
     *  primary success error warning info secondary
     */
    putMsg (type, msg) {
      this.list.unshift({ id: this.nextId(), type, msg })
      this.initTimer()
    },
    initTimer () {
      if (this.timer) {
        return
      }
      const taht = this
      this.timer = setInterval(function () {
        if (taht.list.length > 0) {
          taht.list.pop()
        } else {
          window.clearInterval(taht.timer)
        }
      }, 3000)
    },
    nextId () {
      id++
      return id
    },
    getClass (type) {
      switch (type) {
        case ('primary'):
          return 'alert alert-primary'
        case ('success'):
          return 'alert alert-success'
        case ('error'):
          return 'alert alert-danger'
        case ('warning'):
          return 'alert alert-warning'
        case ('info'):
          return 'alert alert-info'
        case ('secondary'):
          return 'alert alert-secondary'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.bootstrap-alert {
  top: 20px;
  max-width: 434px;
  min-width: 220px;
  width: auto;
  opacity: 0.9;
  position: absolute;
  right: 20px;
  z-index: 10;
  cursor: help;
}
</style>
