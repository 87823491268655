import { createRouter, createWebHashHistory } from 'vue-router'

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue')
const AuthApproval = () => import(/* webpackChunkName: "AuthApproval" */ '@/views/AuthApproval.vue')
const UserPage = () => import(/* webpackChunkName: "UserPage" */ '@/views/UserPage.vue')
const Bind = () => import(/* webpackChunkName: "Bind" */ '@/views/Bind.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      authority: false,
      title: '登录'
    }
  },
  {
    path: '/approval',
    name: 'AuthApproval',
    component: AuthApproval,
    meta: {
      authority: true,
      title: '授权应用'
    }
  },
  {
    path: '/user',
    name: 'UserPage',
    component: UserPage,
    meta: {
      authority: true,
      title: '我的信息'
    }
  },
  {
    path: '/bind',
    name: 'Bind',
    component: Bind,
    meta: {
      authority: true,
      title: '绑定账户'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
