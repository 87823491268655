import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      details: {}
    },
    config: {}
  },
  getters: {
  },
  mutations: {
    setUser (steta, user) {
      steta.user = user
    },
    setConfig (steta, config) {
      steta.config = config
    }
  },
  actions: {

  },
  modules: {
  }
})
